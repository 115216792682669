<template>
  <div class="hello">
    <h1>收到的消息:{{msg}}</h1>
    <button @click="handleclick">发布</button>
  </div>
</template>

<script>
import mqtt from "mqtt";
export default {
  name: "HelloWorld",
  data () {
    return {
      mtopic: "chat/convo/103/msg",
      msg: "lalala",
      client: {}
    };
  },
  mounted () {
    this.client = mqtt.connect("mqtt://test.im.mcn-union.com:8000/mqtt", {
      clientId: "admaasdasdasdin",
      username: "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC90ZXN0LmFwaS53aHR0Lm1jbi11bmlvbi5jb21cL2FwaVwvYXV0aFwvcGhvbmVcL2xvZ2luIiwiaWF0IjoxNjI0OTYyNjU0LCJleHAiOjE2Mjc1NTQ2NTQsIm5iZiI6MTYyNDk2MjY1NCwianRpIjoiWVF3eHByeEt1WGJnUzlaSSIsInN1YiI6MywicHJ2IjoiMjNiZDVjODk0OWY2MDBhZGIzOWU3MDFjNDAwODcyZGI3YTU5NzZmNyIsImFwcEtleSI6IndodHQifQ.HYOZOOuOD-Lz61b55n9YCpMFYy1UQ9xfA0mUttiXNT8",
      cleanSession: false
    });
    this.client.on("connect", e => {
      console.log("连接成功", e);
    });
    this.client.on("message", (topic, message) => {
      console.log(topic)
      this.msg = message
    });
  },
  methods: {
    handleclick: function () {
      console.log(11111)
      this.client.publish(this.mtopic, this.msg);
    }
  }
};
</script>
<style lang='scss' scoped>
.hello {
  padding-top: 100px;
  height: 100vh;
  background: #66f;
}
</style>